/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import CountDown from '../CountDown';
import axios from 'axios';
import { Spin } from 'antd';
import { API_URI_LOOTBOX } from 'env';
// import thumb_banner from '../../assets/images/thumb_slider.png';
// import slide_campain1 from '../../assets/images/slide-campain1.png';
// import slide_campain2 from '../../assets/images/slide-campain2.png';
import { fake_campaigns } from '../../assets/images';
import './campaign.css';
import { campaignService } from 'services/lootbox/campaign';
var settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Campaign = () => {
  const [itemCampain, setItemCampain] = useState({});
  const [imageDefault, setImageDefault] = useState(null);
  const [data, setData] = useState([]);
  const urlImage = API_URI_LOOTBOX;
  console.log(urlImage);
  const [loading, setLoading] = useState(false);
  const getListCampain = async () => {
    try {
      // const profile_lootbox = localStorage.getItem('profile_lootbox');
      // const token = JSON.parse(profile_lootbox).access_token;
      setLoading(true);
      const { data } = await campaignService.campaigns();
      if (data && data.length > 0) {
        let temp = null;
        data.forEach((m) => {
          const nowDate = new Date().getTime();
          const endDate = new Date(m.end_time).getTime();
          const startTime = new Date(m.start_time).getTime();
          if (endDate > nowDate) {
            temp = m;
            // break;
          }
        });
        if (!temp) {
          temp = data[data.length - 1];
        }
        setImageDefault(`${temp.lootbox_campaign.banner_image}`);
        setItemCampain(temp);
      }

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getListCampain();
  }, []);

  const fake_imageDefault = fake_campaigns[0].background_image;

  return (
    <div className='campain-page'>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin loading color='warning' />
        </div>
      ) : (
        <div>
          <div
            className='banner'
            style={{ backgroundImage: `url(${imageDefault ? imageDefault : fake_imageDefault})` }}
          >
            {/* <img src={imageDefault ?imageDefault:fake_imageDefault} className='banner-img' /> */}
            {data && data.length > 0 && <CountDown data={data} />}
          </div>
        </div>
      )}
    </div>
  );
};
export default Campaign;
