/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Select, List, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { images } from 'assets/images';
import { Card, CardBody, CardImg, CardText } from 'reactstrap';
import PopupDetail from '../PopupDetail';
import './style.scss';
import { API_URI_LOOTBOX } from 'env';
const { Option } = Select;

export default function DropItem(props) {
  const { campaign, nfts, dropItem, arrayTotal } = props;
  const [isPopup, setIsPopup] = useState(false);
  const [dropitem, setDropItem] = useState([]);
  const [itemDetail, setItemDetail] = useState({});
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('0');
  useEffect(() => {
    if (nfts && nfts.campaign_wave.lootbox_campaign.nfts) {
      if (filter == '0') {
        setData(nfts.campaign_wave.lootbox_campaign.nfts);
      } else {
        const temp = nfts.campaign_wave.lootbox_campaign.nfts.filter((f) => f.nft_type_id == parseInt(filter));
        setData(temp);
      }
    }
  }, [filter, nfts]);
  const genClass = (name) => {
    if (name == 'Legendary') {
      return 'rarity_btn rarity_legend';
    }
    if (name == 'Epic') {
      return 'rarity_btn rarity_epic';
    }
    if (name == 'Super Rare') {
      return 'rarity_btn rarity_super_rare';
    }
    if (name == 'Rare') {
      return 'rarity_btn rarity_rare';
    }
    return 'rarity_btn rarity_common';
  };
  const getImage = (name) => {
    switch (name) {
      case 'Legendary':
        return images.legend;
        break;
      case 'Epic':
        return images.epic;
        break;
      case 'Super Rare':
        return images.super_rarity;
        break;
      case 'Rare':
        return images.rariryc;
        break;
      case 'Common':
        return images.common;
        break;
      default:
        break;
    }
  };
  return (
    <div className='col-12 drop-item-div'>
      <div className='col-12 p-0 mb-2'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex flex-row align-items-center'>
            <span className='text-title'>Drop Items</span>
          </div>
          <div>
            <Select defaultValue='0' onChange={(e) => setFilter(e)} className='select' style={{ width: '130px' }}>
              <Option value='0'>All</Option>
              <Option value='1'>Legendary</Option>
              <Option value='2'>Epic</Option>
              <Option value='3'>Super rare</Option>
              <Option value='4'>Rare</Option>
              <Option value='5'>Common</Option>
            </Select>
          </div>
        </div>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 2,
          sm: 2,
          md: 4,
          lg: 5,
          xl: 5,
          xxl: 5,
        }}
        dataSource={nfts ? data : []}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              setIsPopup(true);
              setItemDetail(item);
            }}
          >
            <div className='item cursor-pointer'>
              <h5 className={`drop-item-rarity ` + genClass(item.nft_type.label)}>{item && item.nft_type.label}</h5>
              <Card className='item-card'>
                <div className='square-image'>
                  <div
                    className='image'
                    style={{
                      backgroundImage: `url( ${item.image != '<nil>' ? item.image : images.no_image})`,
                      height: '100%',
                      width: '100%',
                    }}
                  ></div>
                </div>
                {/*<CardImg 
                    top
                    width='100%'
                    src={item.image != '<nil>' ?item.image : images.no_image}
                    alt='Card image cap'
                    className='image-card'
                  />*/}
                <CardBody className='drop-item d-flex'>
                  <div className='col-7 p-0 pr-2'>
                    <h4 className='drop-name-item'>{item.name}</h4>
                  </div>
                  <div className='col-5 p-0'>
                    <h6 className='drop-amount-item'>
                      {item.total_supply} item{item.total_supply > 1 ? 's' : ''}
                    </h6>
                  </div>
                </CardBody>
              </Card>
            </div>
          </List.Item>
        )}
      />
      {isPopup ? (
        <div className='popup'>
          <PopupDetail
            item={itemDetail}
            campaign={nfts}
            setItemDetail={setItemDetail}
            dropItem={dropItem}
            arrayTotal={arrayTotal}
          />
          <div onClick={() => setIsPopup(false)} className='btn-cancle'>
            <CloseOutlined style={{ fontSize: '16px', color: '#850000' }} />
          </div>
        </div>
      ) : null}
    </div>
  );
}
