/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { images } from 'assets/images/index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from 'antd';
import { API_URI_LOOTBOX } from 'env';

function PopupDetail(props) {
  const [isShowImages, setIsShowImages] = useState(false);
  const [setting, setSetting] = useState(false);
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 7,
  //   slidesToScroll: 3,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    infinite: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
    ],
  };
  const arr = [
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
  ];
  const getImage = (name) => {
    switch (name) {
      case 'Legandary':
        return images.rarity;
        break;
      case 'Epic':
        return images.rarity1;
        break;
      case 'Super Rare':
        return images.rarity2;
        break;
      case 'Rare':
        return images.rarity3;
        break;
      case 'Common':
        return images.rarity4;
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setSetting({ ...settings, slidesToShow: 2 });
  }, [item]);
  const genClass = () => {
    if (item) {
      const name = item.nft_type.label;
      if (name == 'Legendary') {
        return 'rarity_btn rarity_legend';
      }
      if (name == 'Epic') {
        return 'rarity_btn rarity_epic';
      }
      if (name == 'Super Rare') {
        return 'rarity_btn rarity_super_rare';
      }
      if (name == 'Rare') {
        return 'rarity_btn rarity_rare';
      }
    }

    // if (name == "Rare 2") {
    //     return images.rarity4;
    // }
    return 'rarity_btn rarity_common';
  };

  const getProbability = () => {
    if (item && dropItem) {
      const filter = dropItem.filter((f) => f.nft_type.label == item.nft_type.label);
      if (filter && filter.length > 0) {
        return (filter[0].total_supply / arrayTotal.totalSupply) * 100;
      }
    }
  };

  const { item, campaign, setItemDetail, dropItem, arrayTotal } = props;
  return (
    <div className='wrap' style={{ backgroundColor: 'transparent' }}>
      {isShowImages ? (
        <div>
          <img src={props.item.image} style={{ maxWidth: '100%' }} />
        </div>
      ) : (
        <div className='container' style={{ backgroundColor: 'white' }}>
          <div className='info'>
            <div className='avatar'>
              <img src={props.item.image} onClick={() => setIsShowImages(true)} />
            </div>
            <div className='profile'>
              <div className='title'>{item.name}</div>
              <div className='value'>
                <div className='caption'>Campaign: </div>
                <div className='name-campain'>{campaign && campaign.campaign_wave.lootbox_campaign.title}</div>
              </div>
              <div className='value'>
                <div className='caption'>Artist: </div>
                <div className='bold'>{campaign && campaign.campaign_wave.lootbox_campaign.artist_name}</div>
              </div>
              <div className='value align-items-center'>
                <div className='caption'>Rarity:</div>{' '}
                <h5 style={{ margin: '0px' }} className={`drop-item-rarity ` + genClass()}>
                  {item && item.nft_type.label}
                </h5>
              </div>
              <div className='value'>
                Amount: <div className='bold'>{item && item.total_supply}</div>
              </div>
              <div className='value'>
                Probability: <div className='bold'>{getProbability().toFixed(2)} %</div>
              </div>
              <div style={{ marginTop: 15 }} className='desc'>
                Description
              </div>
              <div className='value'>{item && item.description}</div>
            </div>
          </div>
          {campaign && campaign.campaign_wave.lootbox_campaign.nfts.length > 1 && (
            <div className='list-img'>
              <div className='title-list'>Within this Collection</div>
              <div className='list'>
                <Slider {...setting}>
                  {campaign.campaign_wave.lootbox_campaign.nfts.map((item, index) => (
                    <img
                      onClick={() => setItemDetail(item)}
                      src={item.image}
                      style={{ width: 250 }}
                      key={index}
                      className='img-slide'
                    />
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default PopupDetail;
