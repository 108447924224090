import { client } from './axios';

const campaigns = (params) => client.get(`/wave_public`, { params });
const campaign = (id) => client.get(`/wave_public/${id}`);
const userCampaign = (id) => client.get(`/user_wave_public/${id}`);
const campaignsWallet = (params) => client.get(`campaign_wallet`, { params });
const campaignWallet = (params) => client.get(`campaign_wallet`, { params });
const campaignUpdate = (body) => client.put(`/campain`, body);
const drop = (id) => client.get(`/nft?campaign_wallet=${id}`);
const campaignSearch = (params) => client.get(`/campaign_public`, { params });

const resell = (body) => client.put(`/v1/account/users`, body);

export const campaignService = {
  campaign,
  campaigns,
  campaignUpdate,
  resell,
  campaignsWallet,
  campaignWallet,
  drop,
  campaignSearch,
  userCampaign,
};
