import React, { Fragment, useEffect, useState } from 'react';
import avatar from 'assets/images/avatar.png';
import coin from 'assets/images/coin.png';
import { Card, Button } from 'reactstrap';
import BuyItem from 'components/item/BuyItem';
import { API_URI_LOOTBOX, GAS } from 'env';
import { authorService } from 'services/lootbox/authorService';
import robot from 'assets/images/robot.png';
import shape from '../../assets/images/shape.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Web3 from 'web3';
import { VENDER_CONTRACT } from 'env';
import { venderContract } from 'contracts/lootbox';
import './item.scss';
import { getInputClassName } from 'antd/lib/input/Input';
import { blue } from '@material-ui/core/colors';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { notification, Modal } from 'antd';
import { getAddressBykey, handlePriceItem, shorterName } from 'utils/common';
const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
export default function ItemInfor(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { item } = props;
  const classes = useStyles();
  const [author, setAuthor] = useState(null);
  const history = useHistory();
  const { currencySystem } = useSelector(({ common }) => common);
  const [loading, setLoading] = useState(false);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  useEffect(() => {
    if (item) {
    }
  }, [item]);
  // const getEditions=()=>{
  //     if(item){
  //         const edition = item.Campaign.
  //     }
  // }
  const genClass = () => {
    if (item) {
      if (item.Onsell.NftProfile.nft_type_id == 1) {
        return 'rarity_btn rarity_legend';
      }
      if (item.Onsell.NftProfile.nft_type_id == 2) {
        return 'rarity_btn rarity_epic';
      }
      if (item.Onsell.NftProfile.nft_type_id == 3) {
        return 'rarity_btn rarity_super_rare';
      }
      if (item.Onsell.NftProfile.nft_type_id == 4) {
        return 'rarity_btn rarity_rare';
      }
      if (item.Onsell.NftProfile.nft_type_id == 5) {
        return 'rarity_btn rarity_common';
      }
      return '';
    }
  };
  const genClassName = () => {
    if (item) {
      if (item.Onsell.NftProfile.nft_type_id == 1) {
        return 'Legendary';
      }
      if (item.Onsell.NftProfile.nft_type_id == 2) {
        return 'Epic';
      }
      if (item.Onsell.NftProfile.nft_type_id == 3) {
        return 'Super rare';
      }
      if (item.Onsell.NftProfile.nft_type_id == 4) {
        return 'Rare';
      }
      if (item.Onsell.NftProfile.nft_type_id == 5) {
        return 'Common';
      }
      return '';
    }
  };

  const handleOnClick = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const res = await venderContract(VENDER_CONTRACT)
      .methods.cancelOnSale(item.Onsell.sale_id + '')
      .send({ from: address, gas: GAS })
      .on('transactionHash', function (hash) {
        setLoading(true);
      })
      .on('error', function (error, receipt) {
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        setLoading(false);
        notification.error({ message: 'Cancel put on sell fail' });
      });
    setLoading(false);
    notification.success({ message: 'Cancel put on sell success' });
    history.push('/app/market');
  };
  const handleValue = (userJwt) => {
    return userJwt.substr(0, 6) + '...' + userJwt.substr(userJwt.length - 6, userJwt.length - 1);
  };
  return (
    <Fragment className='flex-row'>
      <div className='col-md-5 pl-0 col-xs-12 image-item'>
        <div className='flex-center col-12 p-24' style={{ maxHeight: 600 }}>
          <img
            onClick={showModal}
            src={item && item.Onsell.NftProfile.image}
            className='cursor-pointer'
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </div>
        <Modal
          className='popup-image'
          closable='true'
          visible={isModalVisible}
          width='50%'
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <img style={{ width: '100%' }} src={item && item.Onsell.NftProfile.image} />
        </Modal>
      </div>

      <div className='col-md-7 col-xs-12 detail-item-infor d-flex flex-column'>
        {/*<Card className="card-itembox col-12 flex-row">*/}
        <div>
          <div className='col-12 d-flex flex-column title p-0'>
            <div style={{ margin: '10px' }}>
              <div className='club'>{item && item.Onsell.NftProfile.title}</div>
              <span className='subject'>{item != null && item.name}</span>
            </div>

            <div className='d-flex'>
              <h5 className={`drop-item-rarity mr-2 ` + genClass()}>{genClassName()}</h5>
              <span>
                {item && item.Onsell.quantity} {item && item.Onsell.quantity > 1 ? 'editions' : 'edition'}
              </span>
            </div>
          </div>
          <div className='col-12 p-0 item-content'>
            <div className='author-infor'>
              <div className='author'>
                <img
                  src={item && item.Owner.avatar ? item.Owner.avatar : avatar}
                  alt=''
                  width='24'
                  className='avattar'
                />
                <span>Owner by</span>{' '}
                <span style={{ fontWeight: 700, marginLeft: 5 }}>
                  {' '}
                  {item != null && shorterName(item.Owner.fullname)}
                </span>
              </div>
              <div className='address'>
                <span>{item && item.Owner && handleValue(item.Owner.wallet_address)}</span>
                <CopyToClipboard text={item && item.Owner.wallet_address}>
                  <img src={shape} alt='' />
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div className='description'>{item && item.Onsell.NftProfile.description}</div>
          {/* <div style={{ position: "absolute", width: "90%", bottom: "30px" }}>
                              <div className="price-product d-flex">
                                  <span className="price-txt">
                                      Price
                                  </span>
                                  <div className="price-value d-flex flex-column f-700">
                                      <span className="price-number">{item ? item.price : ""} USDC</span>
                                      <span className="price-sub-number">$ {item ? item.price : ""}</span>
                                  </div>
                              </div>
                              <BuyItem item={item} />
                          </div> */}
        </div>
        <div className='detail-footer d-flex align-items-end'>
          <div className='price-product col-8 p-0 d-flex'>
            <div className='price-value p-0'>
              {lootboxProfile && item && item.Owner.wallet_address != lootboxProfile.wallet_address && (
                <Fragment>
                  <span className='price-txt'>Price </span>
                  <span className='price-number'>
                    {item &&
                      currencySystem &&
                      handlePriceItem(
                        item.Onsell.price + '',
                        getAddressBykey(currencySystem, item.Currency.label, 'decimal'),
                      ) * item.Onsell.quantity}{' '}
                    {item && item.Currency.label}
                  </span>
                </Fragment>
              )}
            </div>
          </div>
          <div className='col-4 p-0'>
            {lootboxProfile && item && item.Owner.wallet_address != lootboxProfile.wallet_address ? (
              <BuyItem item={item} />
            ) : (
              lootboxProfile && (
                <a onClick={() => handleOnClick()} className='a-button a-button-color a-bg-color-yellow'>
                  {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                  CANCEL
                </a>
              )
            )}
          </div>
        </div>
        {/*</Card>*/}
      </div>
    </Fragment>
  );
}
