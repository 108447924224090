import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import art_thumb from 'assets/images/art_thumb.png';
import { Table } from 'reactstrap';
import { useHistory } from 'react-router';
import './style.scss';
import { Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { lootboxService } from 'services/lootbox/lootbox';
import success from 'assets/images/success-ic.png';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { notification } from 'antd';
import { SPO_CONTRACT, USDT_CONTRACT, USDC_CONTRACT, BUSD_CONTRACT } from 'env';
import { Refresh } from '@material-ui/icons';
import { getAddressBykey } from 'utils/common';
import { useSelector } from 'react-redux';
const { Option } = Select;
const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
const PutOnSaleBoxModal = (props) => {
  const { idLootbox, commissionFee, campaign } = props;
  const classes = useStyles();
  const history = useHistory();
  const [price, setPrice] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalSucess, setModalSuccess] = useState(false);
  const { currencySystem } = useSelector(({ common }) => common);
  const toggle = async () => setModal(!modal);
  const [loading, setLoading] = useState(false);

  const [typeMoney, setTypeMoney] = useState('SPO');
  const handleToggleSuccess = async () => {
    if (!price || price == '' || price <= 0) {
      notification.error({ message: 'Price invalid' });
      return;
    }
    let currencyAdress = getAddressBykey(currencySystem, typeMoney, 'adress');
    if (!currencyAdress) {
      notification.error({ message: 'Invalid currency' });
      return;
    }
    const body = {
      resell_price: price.toString(),
      payment_currency_address: currencyAdress,
    };
    setLoading(true);
    try {
      const checkRes = await lootboxService.lootboxDetail(campaign.Lootbox.id);
      const check = checkRes.data;
      if (check && check.Lootbox.is_opened == true) {
        history.push('/app/box-profile');
        setLoading(false);
        setModal(false);
        notification.error({ message: 'Lootbox opened' });
        return;
      }
      if (check && (check.Lootbox.is_resell == true || check.Lootbox.is_selled == true)) {
        history.push('/app/box-profile');
        setLoading(false);
        setModal(false);
        notification.error({ message: 'Lootbox already put on sale' });
        return;
      }
    } catch (error) {
      history.push('/app/box-profile');
      setLoading(false);
      setModal(false);
      notification.error({ message: 'lootbox not found' });
      return;
    }
    try {
      const res = await lootboxService.resell(idLootbox, body);
      if (res) {
        setModal(false);
      }
    } catch {
      setLoading(false);
      setModal(false);
    }
    setModalSuccess(true);
    setLoading(false);
    // props.refresh()
  };
  const handleChange = (e) => {
    const temp = e.target.value;
    if (temp == null || temp == '' || parseInt(e.target.value) < 0) {
      setPrice('');
      return;
    }
    if (e.target.value != '') {
      setPrice(e.target.value);
    }
  };
  const toggleSuccess = () => setModalSuccess(!modalSucess);
  const selectAfter = (
    <Select
      defaultValue={typeMoney}
      onChange={(value) => setTypeMoney(value)}
      className='select-after'
      suffixIcon={<CaretDownOutlined />}
    >
      {currencySystem &&
        currencySystem.map((m) => {
          return (
            <Option key={m.id} value={m.label}>
              {m.label}
            </Option>
          );
        })}
    </Select>
  );
  return (
    <Fragment>
      <a className='a-button a-bg-color-yellow col-5' onClick={toggle}>
        Put on sale
      </a>
      <Modal
        style={{ maxWidth: '600px', width: '100%' }}
        className='put-on-sale-modal'
        backdrop='static'
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader className='header-put-on-sale' toggle={toggle} style={{ fontSize: '32px !important' }}>
          Put on sale
        </ModalHeader>
        <ModalBody className='body-put-on-sale'>
          <div className='note'>Enter the price you would like to sell</div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img src={campaign.LootboxCampaign.box_image} alt='' className='image-item' />
          </div>
          <div className='group-btn-sale d-flex'>
            <div className='unit-price lable-input' style={{ width: '100%' }}>
              <span className='lable-cus'>Unit price</span>
              <Input
                type='number'
                defaultValue={0}
                value={price}
                onChange={(e) => {
                  handleChange(e);
                }}
                addonAfter={selectAfter}
              />
            </div>
            {/* <div className="amount lable-input">
                        <span className="lable-cus">
                            Amount
                        </span>
                    <Input />
                    </div> */}
          </div>

          <Table className='col-12'>
            <thead>
              <tr>
                <th>Price breakdown</th>
                <th className='column-2'>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope='row'>Selling price</td>
                <td className='column-2'>
                  {price} {typeMoney}
                </td>
              </tr>
              <tr>
                <td scope='row'>Commission fee</td>
                <td className='column-2'>
                  {campaign && price != '' ? Number(((commissionFee / 100) * parseFloat(price)).toFixed(6)) : 0}{' '}
                  {typeMoney}
                </td>
              </tr>
              <tr>
                <td scope='row'>Secondary royalty</td>
                <td className='column-2'>
                  {campaign && price != ''
                    ? Number(((campaign.LootboxCampaign.second_royality_fee / 100) * parseFloat(price)).toFixed(6))
                    : 0}{' '}
                  {typeMoney}
                </td>
              </tr>
            </tbody>
          </Table>

          <div className='receiver d-flex'>
            <div className='txt'>You will receive</div>
            <span className='price-tota'>
              {price != '' &&
              campaign &&
              price -
                (price * parseFloat(commissionFee)) / 100 -
                (price * parseFloat(campaign.LootboxCampaign.second_royality_fee)) / 100 >=
                0
                ? Number(
                    (
                      price -
                      (price * parseFloat(commissionFee)) / 100 -
                      (price * parseFloat(campaign.LootboxCampaign.second_royality_fee)) / 100
                    ).toFixed(6),
                  )
                : 0}{' '}
              {typeMoney}
            </span>
          </div>
        </ModalBody>
        <ModalFooter className='footer-put-on-sale'>
          <Button
            className='btn-mark-lg btn-lootbox btn btn-secondary col-5'
            disabled={loading}
            onClick={handleToggleSuccess}
          >
            PUT ON SALE
          </Button>
        </ModalFooter>
        {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
      </Modal>

      <Modal onClosed={() => props.refresh()} isOpen={modalSucess} toggle={toggleSuccess}>
        <ModalBody>
          <div className='checkout-modal-success d-flex align-item-center'>
            <div className='circle'>
              <img width='60px' height='60px' src={success} alt='' />
            </div>
            <div className='congratulation w-700'>Congratulations!</div>
            <div className='congrat-detail'>
              You have successfuly bought Loot Box{' '}
              <span style={{ color: '#D0B674' }}>{campaign.LootboxCampaign.title}</span>.
            </div>
            <div className='group-btn-checkout'>
              <Button
                onClick={() => {
                  history.push('/app/box-profile');
                }}
                className='btn-lootbox btn-mark'
              >
                Go to Profile
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default PutOnSaleBoxModal;
