import React, { useEffect, useState } from 'react';
import coin from 'assets/images/coin.png';
import './checkout.scss';
import { useHistory } from 'react-router';
import { checkoutService } from 'services/lootbox/checkout';
import { API_URI_LOOTBOX, SPO_CONTRACT, USDT_CONTRACT, USDC_CONTRACT, BUSD_CONTRACT, GAS, PAYMENT_CONTRACT } from 'env';
import success from 'assets/images/success-ic.png';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { spoContract, usdcContract, usdtContract, busdContract } from 'contracts/lootbox';
import {
  TableBody,
  Modal,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import lootbox1 from 'assets/images/lootbox.png';
import { notification } from 'antd';
import campaign from 'reducers/loobox/campaign';
import { getAddressById, getAddressBykey, handlePriceItem } from 'utils/common';
import { useSelector } from 'react-redux';
import Item from 'components/open/Item';
import { lootboxService } from 'services/lootbox/lootbox';

const useStyles = makeStyles({
  table: {},
  checkoutModal: {
    '& .MuiDialog-paperWidthSm': {
      width: 568,
      padding: '0 !important',
    },
    '& .MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },

  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
const CheckoutModal = (props) => {
  const classes = useStyles();
  const { className, lootbox } = props;

  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState(null);
  const [modalSucess, setModalSuccess] = useState(false);
  const [capchar, setCapchar] = useState(false);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const { currencySystem } = useSelector(({ common }) => common);
  const toggle = async () => {
    setModal(!modal);
    setCapchar(false);
  };
  const [loading, setLoading] = useState(false);
  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  const genCurrency = (value) => {
    if (lootbox) {
      switch (lootbox.Lootbox.currency_id) {
        case 1:
          return 'SPO';
        case 2:
          return 'USDT';
        case 3:
          return 'USDC';
        case 4:
          return 'BUSD';
      }
    }
  };

  const handleTranfer = async () => {
    if (!capchar) {
      return;
    }
    if (lootboxProfile.id == lootbox.Lootbox.owner) {
      notification.error({ message: 'You are owner this lootbox' });
      return;
    }
    const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    let contract = null;
    let currency = getAddressById(currencySystem, lootbox.Lootbox.currency_id, 'adress');
    switch (lootbox.Lootbox.currency_id) {
      case 1:
        contract = spoContract;
        break;
      case 2:
        contract = usdtContract;
        break;
      case 3:
        contract = usdcContract;
        break;
      case 4:
        contract = busdContract;
        break;
    }
    if (!contract || !currency) {
      notification.error({ message: 'Currency incorrect' });
      return;
    }
    //check money
    setLoading(true);

    try {
      const res = await lootboxService.secondSelaItem(lootbox.Lootbox.id);
    } catch (error) {
      setLoading(false);
      history.push('/app/market');
      return;
    }

    const decimal = getAddressById(currencySystem, lootbox.Lootbox.currency_id, 'decimal');
    const balance = await contract(currency).methods.balanceOf(address).call();
    const priceBuyCheck = lootbox.Lootbox.resell_price;
    if (parseFloat(priceBuyCheck) > handlePriceItem(balance, decimal)) {
      notification.error({ message: 'Not enought money' });
      setLoading(false);
      return null;
    }

    const res = await contract(currency)
      .methods.approve(
        lootbox.LootboxCampaign.transfer_out_wallet.address,
        handlePrice(lootbox.Lootbox.resell_price, decimal),
      )
      .send({ from: address, gasLimit: GAS })
      .on('transactionHash', function (hash) {
        setLoading(true);
      })
      .on('error', function (error, receipt) {
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        setLoading(false);
      });
    const body = {
      lootbox_id: lootbox.Lootbox.id,
      transaction_hash: res.transactionHash,
      payment_currency_address: getAddressById(currencySystem, lootbox.Lootbox.currency_id, 'adress'),
    };
    let ress = null;
    //  await sleep(5000)
    try {
      ress = await checkoutService.secondboxcheckout(body);
    } catch (err) {
      setModal(false);
      setLoading(false);
    }
    if (ress && ress.status == 400) {
      notification.error({ message: ress.message });
      setModal(false);
    } else {
      setModal(false);
      setModalSuccess(true);
    }
    setLoading(false);
  };
  const handlePrice = (price, number) => {
    let temp = parseFloat(price);
    for (let i = 0; i < number; i++) {
      temp = temp * 10;
      if (isInt(temp)) {
        let v = number - (i + 1);
        for (let j = 0; j < v; j++) {
          temp = temp + '0';
        }
        return temp;
      }
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleSetChap = (e) => {
    if (e) {
      setCapchar(true);
    } else {
      setCapchar(false);
    }
  };

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }
  // const handleBuyboxWithCurrecy = async (contract, currecy, address) => {
  //   const allow = await contract(currency).methods.approve(PAYMENT_CONTRACT,p).send({from :address, gasLimit: GAS}).on('transactionHash', function(hash){
  //     setLoading(true)
  // }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
  //     setLoading(false)
  // });
  //   return await contract(currecy).methods.transfer(lootbox.LootboxCampaign.transfer_out_wallet.address, handlePrice(parseFloat(lootbox.Lootbox.resell_price).toFixed(),18)).send({ from: address, gasLimit: GAS }).on('transactionHash', function (hash) {
  //     setLoading(true)
  //   }).on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
  //     setLoading(false)
  //   });
  // }
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const toggleSuccess = () => setModalSuccess(!modalSucess);
  return (
    <div className='col-5 p-0'>
      <a onClick={toggle} className='a-button a-button-color a-bg-color-yellow'>
        BUY NOW
      </a>
      {/*<Button className="buy btn-mark-lg" onClick={toggle}>BUY NOW</Button>*/}
      <Dialog className={classes.checkoutModal} onClose={toggle} aria-labelledby='customized-dialog-title' open={modal}>
        <DialogTitle
          className='d-flex justify-content-center checkout-title'
          style={{ textAlign: 'center', background: '#F9F9F9', borderBottom: 'none' }}
          id='customized-dialog-title'
          onClose={toggle}
        >
          <h8 style={{ fontWeight: 600, textAlign: 'center', fontSize: '25px !important', textAlign: 'center' }}>
            Checkout
          </h8>
        </DialogTitle>
        <DialogContent dividers style={{ borderTop: 'none', padding: '10px 63px', borderBottom: 'none' }}>
          <TableContainer style={{ overflowX: 'none' }}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 700 }}>Item</TableCell>
                  <TableCell style={{ fontWeight: 700 }} align='right'>
                    Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[{}].map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component='th' scope='row'>
                      <img src={lootbox ? lootbox.LootboxCampaign.box_image : ''} width='40px' />{' '}
                      <span style={{ color: '#CFB675', fontSize: '16px', fontWeight: 'bold' }}>
                        {lootbox && lootbox.LootboxCampaign.title}
                      </span>
                    </TableCell>
                    <TableCell align='right'>
                      {lootbox && lootbox.Lootbox.resell_price}{' '}
                      {lootbox && getAddressById(currencySystem, lootbox.Lootbox.currency_id, 'label')}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ borderBottom: 'none', fontWeight: 700 }} component='th' scope='row'>
                    Total
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none', color: '#CFB675', fontWeight: 'bold' }} align='right'>
                    {lootbox && lootbox.Lootbox.resell_price}{' '}
                    {lootbox && getAddressById(currencySystem, lootbox.Lootbox.currency_id, 'label')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ borderBottom: 'none' }} component='td'>
                    <div className='d-flex justify-content-center'>
                      <Box style={{ textAlign: 'center', width: '300px' }}>
                        <ReCAPTCHA
                          onChange={(e) => {
                            handleSetChap(e);
                          }}
                          className='recap'
                          sitekey='6Lf0i4EcAAAAAOYMw3gt-khTM16WHHwDTwbtINP2'
                        />
                      </Box>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ borderBottom: 'none' }} component='td'>
                    <DialogActions
                      style={{
                        borderTop: 'none',
                        background: '#F9F9F9',
                        justifyContent: 'center',
                        width: '100%',
                        padding: '0px calc(50% - 150px) !important',
                      }}
                    >
                      <Button
                        style={{ textTrasform: 'uppercase', border: 'none', fontWeight: 600, width: '300px' }}
                        autoFocus
                        onClick={() => handleTranfer()}
                        disabled={loading}
                        className='btn-checkout btn-mark-lg'
                        color='primary'
                      >
                        CHECKOUT
                      </Button>
                      {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                    </DialogActions>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        className={classes.checkoutModal}
        onClose={modalSucess}
        aria-labelledby='customized-dialog-title'
        open={modalSucess}
      >
        <DialogContent dividers style={{ borderTop: 'none', padding: '10px 63px', borderBottom: 'none' }}>
          <div className='checkout-modal-success d-flex align-item-center'>
            <div className='circle'>
              <img width='60px' height='60px' src={success} alt='' />
            </div>
            <div className='congratulation w-700'>Congratulations!</div>
            <div className='congrat-detail'>
              You have successfuly bought Loot Box{' '}
              <span style={{ color: '#D0B674' }}> {lootbox && lootbox.LootboxCampaign.title}</span>.
            </div>
            <div className='group-btn-checkout'>
              <Button onClick={() => history.push('/app/box-profile')} className='btn-lb btn-mark-wh'>
                Go to Profile
              </Button>
              <Button onClick={() => history.push('/app/home')} className='btn-checkout btn-mark-lg'>
                Continue buying
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default CheckoutModal;
