import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, List, Menu, Select, Space, Pagination } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import opened from '../../assets/images/opened.png';
import robot from '../../assets/images/robot.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './boxProfile.scss';
import DropItem from './DropItem';
import { lootboxService } from 'services/lootbox/lootbox';
import { useSelector } from 'react-redux';
import RowDropItem from './RowDropItem';
const { SubMenu } = Menu;
const { Option } = Select;

const BoxProfile = (props) => {
  const { profile, lootbox, owner } = props;
  const width = useWindowDimensions().width;
  const [current, setCurrent] = useState();
  const [dropdownOpen, setDropDownOpen] = useState('mail');
  const [type, setType] = useState('box');
  const [image, setImage] = useState(lootbox);
  const [collapsed, setCollapsed] = useState(false);
  const { currencySystem } = useSelector(({ common }) => common);
  const [items, setItem] = useState([]);
  const [sort, setSort] = useState('created_at_desc');
  const [currncyfilter, setcurrencyFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(12);
  const [total, setTotal] = useState(0);
  const [boxCampaignFilter, setBoxCampaignFilter] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (lootbox && sort) {
      handleType(type);
    }
  }, [lootbox, sort, page, type, currncyfilter]);

  const handleRedirect = (id, t) => {
    if (type == 'open') {
      return;
    }
    if (t == 'box') {
      history.push('/app/detail-box-open/' + id);
    }
    if (t == 'item') {
      history.push('/app/item-detail-sale/' + id);
    }
    if (type == 'onsell' && t == 'item') {
      history.push('/app/item-sale/' + id);
    }
  };

  const makeUrl = (id, t) => {
    if (type == 'open') {
      return;
    }
    if (t == 'box') {
      return '/app/detail-box-open/' + id;
    }
    if (t == 'item' && type != 'onsell') {
      return '/app/item-detail-sale/' + id;
    }
    if (type == 'onsell' && t == 'item') {
      return '/app/item-sale/' + id;
    }
  };

  const handleType = async (type) => {
    let res = [];
    if (type == 'box') {
      const open = await lootboxService.lootboxList({
        owner: owner,
        is_opened: 2,
        is_resell: 2,
        page: 1,
        pagesize: 1000,
        'currency_ids[]': !currncyfilter || currncyfilter == '0' ? undefined : currncyfilter,
      });
      if (open && open.data && open.data.length > 0) {
        const tempbox = [];
        open.data.map((m) => {
          tempbox.push({ ...m, type: 'box' });
        });
        if (tempbox && tempbox.length > 0) {
          const pagi = handleFilterCampaign(tempbox);
          if (pagi && pagi.length > 0) {
            res = pagi.slice((page - 1) * size, page * size);
            setTotal(pagi.length);
          }
        }
        setBoxCampaignFilter(tempbox);
      }
    }
    if (type == 'item') {
      const param = {
        user_id: owner,
        sort: sort,
        page: page,
        pagesize: size,
      };
      const ress = await lootboxService.userNft(param);
      const temp = ress.data.filter((f) => {
        if (f.amount_nft == 0) {
          return false;
        } else if (
          (f.amount_nft != 0 && f.amount_onsell && parseInt(f.amount_onsell) < f.amount_nft) ||
          !f.amount_onsell
        ) {
          return true;
        }
        return false;
      });
      if (temp && temp.length > 0) {
        setTotal(ress.total);
        temp.map((m) => {
          res.push({ ...m, type: 'item' });
        });
      }
    }
    if (type == 'open') {
      const open = await lootboxService.lootboxList({
        owner: owner,
        is_opened: 1,
        is_resell: 2,
        page: page,
        pagesize: size,
        'currency_ids[]': !currncyfilter || currncyfilter == '0' ? undefined : currncyfilter,
      });
      if (open && open.data && open.data.length > 0) {
        setTotal(open.total);
        open.data.map((m) => {
          res.push({ ...m, type: 'box' });
        });
      }
    }
    if (type == 'all') {
      const open = await lootboxService.lootboxList({
        owner: owner,
        is_opened: 2,
        is_resell: 2,
        page: page,
        pagesize: size,
        'currency_ids[]': !currncyfilter || currncyfilter == '0' ? undefined : currncyfilter,
      });
      if (open && open.data && open.data.length > 0) {
        setTotal(open.total);
        open.data.map((m) => {
          res.push({ ...m, type: 'box' });
        });
      }
      const param = {
        user_id: owner,
        sort: sort,
        page: page,
        pagesize: size,
      };
      const ress = await lootboxService.userNft(param);
      const temp = ress.data.filter((f) => {
        if ((f.amount_nft != 0 && f.amount_onsell && parseInt(f.amount_onsell) < f.amount_nft) || !f.amount_onsell) {
          return true;
        }
        return false;
      });
      if (temp && temp.length > 0) {
        setTotal(ress.total);
        temp.map((m) => {
          res.push({ ...m, type: 'item' });
        });
      }
    }

    if (type == 'onsell') {
      const open = await lootboxService.lootboxList({
        owner: owner,
        is_resell: 1,
        is_opened: 2,
        page: page,
        pagesize: size,
        'currency_ids[]': !currncyfilter || currncyfilter == '0' ? undefined : currncyfilter,
      });
      if (open && open.data && open.data.length > 0) {
        setTotal(open.total);
        open.data.map((m) => {
          res.push({ ...m, type: 'box' });
        });
      }
      const param = {
        owner: owner,
        Sort: sort,
        page: page,
        pagesize: size,
        'currency_ids[]': !currncyfilter || currncyfilter == '0' ? undefined : currncyfilter,
      };
      const ress = await lootboxService.marketItem(param);
      const temp = ress.data;

      if (temp && temp.length > 0) {
        setTotal(ress.total);
        temp.map((m) => {
          res.push({ ...m, type: 'item' });
        });
      }
    }

    setType(type);
    setItem(res);
    setCollapsed(false);
  };
  const handleFilterCampaign = (lootbox) => {
    return lootbox.reduce((a, c) => {
      let filtered = a.filter((f) => f.CampaignWave.lootbox_campaign_id == c.CampaignWave.lootbox_campaign_id);
      if (filtered && filtered.length == 0) {
        const tem = { ...c, totalBox: 1 };
        a.push(tem);
      } else if (filtered && filtered.length > 0) {
        a[a.indexOf(filtered[0])].totalBox += 1;
      }
      return a;
    }, []);
  };
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const toggle = () => {
    setDropDownOpen(!dropdownOpen);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleEmptyItem = () => {
    setItem([]);
  };

  const chunk = (array) => {
    const res = [];
    var i,
      j,
      temporary,
      chunk = 4;
    for (i = 0, j = array.length; i < j; i += chunk) {
      temporary = array.slice(i, i + chunk);
      res.push(temporary);
    }
    return res;
  };

  const handleSetType = (typeB) => {
    setType(typeB);
    setPage(1);
    setTotal(0);
    if (typeB != type) {
      setItem([]);
    }
  };

  return (
    <Fragment>
      <div className='box-profile-main d-flex'>
        <Space>
          <Button
            className={`btn-hanlde-lb ${type == 'box' ? 'btn-acctive' : ''}`}
            onClick={() => {
              handleSetType('box');
            }}
          >
            Boxes
          </Button>
          <Button
            className={`btn-hanlde-lb ${type == 'item' ? 'btn-acctive' : ''}`}
            onClick={() => {
              handleSetType('item');
            }}
          >
            Items
          </Button>
          <Button
            className={`btn-hanlde-lb ${type == 'onsell' ? 'btn-acctive' : ''}`}
            onClick={() => {
              handleSetType('onsell');
            }}
          >
            On sale
          </Button>
          <Button
            className={`btn-hanlde-lb ${type == 'open' ? 'btn-acctive' : ''}`}
            onClick={() => {
              handleSetType('open');
            }}
          >
            Opened
          </Button>
        </Space>
        <Space style={{ alignItems: 'end' }} className='group-btn-sort'>
          {type != 'item' && (
            <Select
              className='btn-hanlde-lb'
              onChange={(value) => setcurrencyFilter(value)}
              defaultValue='All'
              style={{ width: '120px', alignItems: 'start' }}
            >
              <Option value='0'>All</Option>
              {currencySystem &&
                currencySystem.map((m) => {
                  return (
                    <Option key={m.id} value={m.id}>
                      {m.label}
                    </Option>
                  );
                })}
            </Select>
          )}
          <Select
            className='btn-hanlde-lb'
            defaultValue={sort}
            onChange={(value) => setSort(value)}
            style={{ width: '120px', alignItems: 'start' }}
          >
            <Option value='created_at_desc'>Recently added</Option>
            <Option value='campaign_title_desc'>Sort A to Z</Option>
          </Select>
        </Space>
      </div>
      <div className={`row list-drop-items ${type == 'open' ? ' bongmo' : ''}`}>
        <div className='list-item mt-4' style={{ width: '100vw' }}>
          {type != 'box' && (
            <List
              grid={{
                gutter: 16,
                xs: 2,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4,
                xxl: 4,
              }}
              dataSource={items}
              renderItem={(i) => (
                <Fragment>
                  <List.Item
                  // onClick={() => {
                  //     handleRedirect(i.id, i.type);
                  // }}
                  >
                    {/*<a href={ makeUrl(i.id,i.type) }><DropItem item={i} type={type} className={type} /></a>*/}
                    <Link to={makeUrl(i.id, i.type)}>
                      <DropItem item={i} type={type} className={type} />
                    </Link>
                  </List.Item>
                </Fragment>
              )}
            />
          )}
          {type == 'box' &&
            items &&
            items.length > 0 &&
            chunk(items).map((m, index) => {
              return (
                <RowDropItem key={index} items={m} page={page} boxCampaignFilter={boxCampaignFilter} type={type} />
              );
            })}
        </div>
      </div>
      {total && total > 0 && total > size ? (
        <Pagination
          style={{ textAlign: 'center', margin: '20px 0' }}
          defaultCurrent={1}
          onChange={(page, size) => {
            setPage(page);
          }}
          pageSize={size}
          total={total}
          showSizeChanger={false}
        />
      ) : (
        ''
      )}
    </Fragment>
  );
};
export default BoxProfile;
