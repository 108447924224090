import React, { Fragment, useState } from 'react';
import avatar from 'assets/images/avatar.png';
import coin from 'assets/images/coin.png';
import { Card, Button } from 'reactstrap';
import PutOnSaleModal from '../put-on-sale/PutOnSaleModal';

import './item.scss';
import lootbox from 'reducers/loobox/lootbox';
import shape from '../../assets/images/shape.png';
import { API_URI_LOOTBOX, GAS, VENDER_CONTRACT } from 'env';
import { venderContract } from 'contracts/lootbox';
import Web3 from 'web3';
import { blue } from '@material-ui/core/colors';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { notification, Modal } from 'antd';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getAddressBykey, handlePriceItem, shorterName } from 'utils/common';

const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
export default function ItemInfor(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const classes = useStyles();
  const history = useHistory();
  const { lootbox, commissionFee } = props;
  const [loading, setLoading] = useState(false);
  const { currencySystem } = useSelector(({ common }) => common);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const handleOnClick = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const res = await venderContract(VENDER_CONTRACT)
      .methods.cancelOnSale(lootbox.Onsell.sale_id + '')
      .send({ from: address, gas: GAS })
      .on('transactionHash', function (hash) {
        setLoading(true);
      })
      .on('error', function (error, receipt) {
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        setLoading(false);
        notification.error({ message: 'Cancel put on sell fail' });
      });
    setLoading(false);
    notification.success({ message: 'Cancel put on sell success' });
    history.push('/app/box-profile');
  };
  const genClass = () => {
    if (lootbox) {
      if (lootbox.Onsell.NftProfile.nft_type_id == 1) {
        return 'rarity_btn rarity_legend';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 2) {
        return 'rarity_btn rarity_epic';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 3) {
        return 'rarity_btn rarity_super_rare';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 4) {
        return 'rarity_btn rarity_rare';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 5) {
        return 'rarity_btn rarity_common';
      }
      return '';
    }
  };
  const genClassName = () => {
    if (lootbox) {
      if (lootbox.Onsell.NftProfile.nft_type_id == 1) {
        return 'Legendary';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 2) {
        return 'Epic';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 3) {
        return 'Super rare';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 4) {
        return 'Rare';
      }
      if (lootbox.Onsell.NftProfile.nft_type_id == 5) {
        return 'Common';
      }
      return '';
    }
  };
  return (
    <Fragment className='flex-row'>
      <div className='col-md-5 pl-0 col-xs-12 image-item'>
        <div className='flex-center col-12 p-24' style={{ maxHeight: 600 }}>
          <img
            onClick={showModal}
            src={lootbox?.Onsell?.NftProfile?.image}
            className='cursor-pointer'
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </div>

        <Modal
          className='popup-image'
          closable='true'
          visible={isModalVisible}
          width='50%'
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <img
            style={{ width: '100%' }}
            src={lootbox && lootbox.image != '<nil>' ? lootbox.Onsell.NftProfile.image : coin}
          />
        </Modal>
      </div>
      <div className='col-md-7 col-xs-12 col-md-7 col-xs-12 detail-item-infor d-flex flex-column check1'>
        {/*<Card className="card-itembox row flex-row col-12">*/}
        <div>
          <div className='col-12 p-0 d-flex flex-column title'>
            <div className='club'>{lootbox && lootbox.Campaign.title}</div>
            <span className='subject'>{lootbox && lootbox.Campaign.content}</span>
            <div className='d-flex'>
              {/*<Button style={{ width: 87, marginRight:5 }}  className={genClass()}>{genClassName()} </Button>*/}
              <h5 className={`drop-item-rarity mr-2 ` + genClass()}>{genClassName()}</h5>
              <span>
                {' '}
                {lootbox && lootbox.Onsell.quantity} {lootbox && lootbox.Onsell.quantity > 1 ? 'items' : 'item'}
              </span>
            </div>
          </div>

          <div className='col-12 p-0 item-content'>
            <span className='description-title'>Description</span>
            <div className='author-infor'>
              <div className='author'>
                <img
                  src={lootboxProfile && lootboxProfile.avatar ? lootboxProfile.avatar : avatar}
                  alt=''
                  width='24'
                  className='avattar'
                />
                <span>Owner by</span>{' '}
                <span style={{ fontWeight: 700, marginLeft: 5 }}>
                  {' '}
                  {lootboxProfile && shorterName(lootboxProfile.fullname)}
                </span>
              </div>
            </div>
          </div>
          <div className='col-12 p-0 description'>{lootbox && lootbox.Onsell.NftProfile.description}</div>
        </div>
        <div className='cancel-item-footer align-items-end' style={{ marginTop: 25, width: '100%' }}>
          <div>
            <div>Price</div>
            <div style={{ fontSize: 24, color: '#C8AA5D' }}>
              {lootbox &&
                lootboxProfile &&
                Number(
                  (
                    handlePriceItem(
                      lootbox.Onsell.price,
                      getAddressBykey(currencySystem, lootbox.Currency.label, 'decimal'),
                    ) * lootbox.Onsell.quantity
                  ).toFixed(6),
                )}{' '}
              {lootbox && lootbox.Currency.label}
            </div>
          </div>
          <Button className='btn-mark-lg btn-lootbox' style={{ position: 'relative' }} onClick={() => handleOnClick()}>
            {!loading ? 'Cancel' : <CircularProgress className={classes.buttonProgress} size={24} />}
          </Button>
        </div>

        {/*</Card>                    */}
      </div>
    </Fragment>
  );
}
