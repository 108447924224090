import Box from 'components/boxprofile/BoxProfile';
import HeaderBoxProfile from 'components/boxprofile/HeaderBoxProfile';
import Profile from 'components/boxprofile/Profile';
import React, { Fragment, useEffect, useState } from 'react';
import { lootboxService } from 'services/lootbox/lootbox';
import { userServiceLb } from 'services/lootbox/user';
import './boxProfile.scss';
import { jwtDecode, shorterName } from 'utils/common';
import { Card } from 'antd';
import tt from 'assets/images/tt.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import shape from '../../../assets/images/shape.png';
import { API_URI_LOOTBOX } from 'env';
import { shortenAddress } from 'utils/common';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Avatar, Dialog, IconButton, Tooltip } from '@material-ui/core';

const { Meta } = Card;

const BoxProfile = () => {
  const [profile, setProfile] = useState(null);
  const [lootbox, setLootbox] = useState(null);
  const [owner, setOwner] = useState([]);
  const { currencySystem } = useSelector(({ common }) => common);
  const history = useHistory();

  useEffect(async () => {
    const res = await userServiceLb.getProfile();
    setProfile(res.data);
    const token = localStorage.getItem('profile_lootbox');

    if (token) {
      const tokenJson = JSON.parse(token);
      const payload = jwtDecode(tokenJson.access_token);
      const res2 = await lootboxService.lootboxList({
        owner: payload.id,
      });
      setOwner(payload.id);
      setLootbox(res2.data);
    }
  }, []);

  return (
    <Fragment>
      <div className='box-profile-page'>
        <HeaderBoxProfile profile={profile} />
        <div className='container body-profile'>
          {/* <Profile profile={profile} lootbox={lootbox} /> */}
          <div className='row'>
            <div className='col-3 p-0 left'>
              <div className='card-avatar'>
                <Card
                  hoverable
                  style={{ width: '100%', height: '100%', cursor: 'initial' }}
                  cover={
                    <Avatar
                      onClick={() => history.push('/app/profile-update')}
                      src={profile ? profile.avatar : ''}
                      style={{
                        width: 210,
                        height: 210,
                      }}
                    />
                  }
                >
                  <h3>{profile && shorterName(profile.fullname)}</h3>
                  <CopyToClipboard className='CopyToClipboard col-12' text={profile && profile.wallet_address}>
                    <div>
                      <span>{profile && shortenAddress(profile.wallet_address, 6, 6)}</span>
                      <img src={shape} alt='Copy to clipboard' />
                    </div>
                  </CopyToClipboard>
                  <div>{profile && profile.profile}</div>
                </Card>
              </div>
            </div>
            <div pt={2} className='col-9'>
              <Box lootbox={lootbox} owner={owner} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BoxProfile;
