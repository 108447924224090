import robot from 'assets/images/robot.png';
import { Card, CardBody, CardImg, CardText } from 'reactstrap';
import './boxProfile.scss';
import lootbox from 'assets/images/lootbox-3.png';
import tt from 'assets/images/tt.png';
import opened from 'assets/images/opened.png';
import avatar from 'assets/images/avatar.png';
import { API_URI_LOOTBOX } from 'env';
import moment from 'moment';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { getAddressById, getAddressBykey, handlePriceItem, shorterName } from 'utils/common';
import { commonAction } from 'actions/lootbox/common';

export default function DropItemBox(props) {
  const { item, type } = props;

  const image = item.image === '<nil>' ? undefined : item.image;
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const { currencySystem } = useSelector(({ common }) => common);

  // const calDay=()=>{
  //     const date = new Date();
  //     a.diff(, 'days')
  // }
  //API_URI_LOOTBOX + item.CampaignWave.LootboxCampaign.box_image

  const genClass = (name) => {
    if (name == 'Legendary') {
      return 'rarity_btn rarity_legend';
    }
    if (name == 'Epic') {
      return 'rarity_btn rarity_epic';
    }
    if (name == 'Super Rare') {
      return 'rarity_btn rarity_super_rare';
    }
    if (name == 'Rare') {
      return 'rarity_btn rarity_rare';
    }
    // if (name == "Rare 2") {
    //     return images.rarity4;
    // }
    return 'rarity_btn rarity_common';
  };
  const price =
    item && item.type === 'box'
      ? item.resell_price
      : handlePriceItem(item.price + '', getAddressBykey(currencySystem, item.currency_label, 'decimal')) *
        item.quantity;

  const gendImage = () => {
    if (item) {
      if (item.type == 'box') {
        return item.CampaignWave.LootboxCampaign.box_image;
        // return lootbox
      }
      if (item.type == 'item') {
        return item.image;
      }
      if (item.type == 'open') {
        return item.CampaignWave.LootboxCampaign.box_image;
      }
    }
  };

  const diffDate = (endtime) => {
    const date1 = new Date();
    const date2 = new Date(endtime);
    const diffTime = Math.abs(date2 - date1);

    if (diffTime > 0) {
      const day = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[0];
      const hour = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[1];
      const minute = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[2];
      if (day && day != '00') {
        return day + ' days left to open';
      }
      if (hour && hour != '00') {
        return hour + ' hour left to open';
      }
      if (minute && minute != '00' && minute != '01') {
        return minute + ' minutes left to open';
      }
    }
    return '';
  };
  const handleShowPopup = () => {
    commonAction.setItems(item);
    props.clickItem(item);
  };
  return (
    <div className='' onClick={() => handleShowPopup()}>
      <Card className='card-item' hoverable>
        {item && item.type == 'box' && (
          <p style={{ minHeight: 24 }}>{diffDate(item.CampaignWave.LootboxCampaign.open_end_time)}</p>
        )}
        {item && item.type == 'item' && (
          <p>
            <div className='d-flex' style={{ justifyContent: 'space-between' }}>
              <h5 className={`drop-item-rarity ` + genClass(item.nft_types)}>{item && item.nft_types}</h5>
              <div>
                x
                {(item && type == 'item') || (type == 'all' && item.type == 'item')
                  ? item.amount_nft - item.amount_onsell
                  : type == 'onsell' && item.quantity
                  ? item.quantity
                  : 0}
              </div>
            </div>
          </p>
        )}
        <div className='square-image'>
          <div
            className='image'
            style={{ backgroundImage: `url( ${gendImage()})`, height: '100%', width: '100%' }}
          ></div>
        </div>
        {/*<div className="image-div"><CardImg top src={gendImage()} alt='Card image cap' /></div>*/}
        <CardBody style={{ padding: '5px 5px' }}>
          <CardText>
            {/*{item && item.type == "box" && <span>{item && item.campaign_title} #{item && item.id}</span>}*/}
            {/*{item && item.type == "box" && <span>{item && item.campaign_title} #{item && item.id}</span>}*/}
            {item && item.type == 'box' && (
              <span style={{ fontWeight: '600' }}>{item && item.CampaignWave.LootboxCampaign.title}</span>
            )}
            {/* {item && item.type == "item" && <span style={{fontWeight:"600"}}>{item && item.name}</span>} */}
            <button style={{ borderRadius: 12, padding: '2px 8px', height: 24, border: 'none', float: 'right' }}>
              x{item && item.totalBox}
            </button>
          </CardText>
          <CardText className='avatar-c d-flex align-items-center'>
            <img
              src={lootboxProfile && lootboxProfile.avatar ? lootboxProfile.avatar : avatar}
              width='15px'
              height='15px'
              alt=''
              style={{ marginRight: 5, borderRadius: '50%' }}
            />
            {lootboxProfile && shorterName(lootboxProfile.fullname)}
          </CardText>
          {type && type == 'onsell' && (
            <CardText className='pice-div'>
              <span className='price-title'>Price</span>
              <span className='price'>
                {price}{' '}
                {item && item.type == 'item'
                  ? item.currency_label
                  : item.type == 'box'
                  ? getAddressById(currencySystem, item.currency_id, 'label')
                  : ''}{' '}
              </span>
            </CardText>
          )}
        </CardBody>
      </Card>
    </div>
  );
}
