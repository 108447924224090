import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import art_thumb from 'assets/images/art_thumb.png';
import { Table } from 'reactstrap';
import { useHistory } from 'react-router';
import './style.scss';
import { Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { paymentContract, venderContract, commonContract } from 'contracts/lootbox/index';
import success from 'assets/images/success-ic.png';
import { lootboxService } from 'services/lootbox/lootbox';
import Web3 from 'web3';
import { blue } from '@material-ui/core/colors';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { notification } from 'antd';

import {
  PAYMENT_CONTRACT,
  SPO_CONTRACT,
  VENDER_CONTRACT,
  FACTORY_CONTRACT,
  USDC_CONTRACT,
  USDT_CONTRACT,
  BUSD_CONTRACT,
  API_URI_LOOTBOX,
  GAS,
} from 'env';
import { useSelector } from 'react-redux';
import { getAddressBykey } from 'utils/common';
const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
const { Option } = Select;
const PutOnSaleModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { lootbox, commissionFee } = props;
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(1);
  const [modal, setModal] = useState(false);
  const [modalSucess, setModalSuccess] = useState(false);
  const { currencySystem } = useSelector(({ common }) => common);
  const toggle = () => setModal(!modal);
  const [typeMoney, setTypeMoney] = useState('SPO');
  const [loading, setLoading] = useState(false);
  const handlePrice = (e) => {
    const temp = e.target.value;
    if (parseInt(temp) < 0 || temp == null || temp == '') {
      setPrice('');
      return;
    }
    if (temp != null && temp != '') {
      setPrice(parseFloat(temp));
    }
  };
  const handleAmount = (e) => {
    const temp = e.target.value;
    if (parseInt(temp) < 0 || temp == null || temp == '') {
      setAmount('');
      return;
    }
    const diff = lootbox.amount_onsell ? lootbox.amount - parseInt(lootbox.amount_onsell) : lootbox.amount;
    if (parseInt(temp) > diff) {
      setAmount(lootbox.amount - parseInt(lootbox.amount_onsell));
      return;
    }
    if (temp != null && temp != '') {
      setAmount(parseInt(temp));
    }
  };
  const handleDecimal = (price, number) => {
    let temp = parseFloat(price);
    for (let i = 0; i < number; i++) {
      temp = temp * 10;
      if (isInt(temp)) {
        let v = number - (i + 1);
        for (let j = 0; j < v; j++) {
          temp = temp + '0';
        }
        return temp;
      }
    }
  };

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }
  const handleToggleSuccess = async () => {
    if (!price || price == '' || price <= 0) {
      notification.error({ message: 'Price invalid' });
      return;
    }

    if (!amount || amount == '' || amount <= 0) {
      notification.error({ message: 'Amount invalid' });
      return;
    }
    // const priceSell = parseInt(price) - commissionFee - parseInt(lootbox.second_royality_fee)
    // if (price <= 0) {
    //     notification.error({ message: "Price invalid" })
    //     return;
    // }
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }

    let currencyAdress = getAddressBykey(currencySystem, typeMoney, 'adress');

    try {
      const checkRes = await lootboxService.userNftOne(lootbox.id);
      const check = checkRes.data;
      let amount_onsell = 0;
      if (check.amount_onsell) {
        amount_onsell = parseInt(check.amount_onsell);
      }
      if (check && (check.amount == 0 || check.amount - amount_onsell <= 0)) {
        notification.error({ message: 'Item already put on sale' });
        history.push('/app/box-profile');
        return;
      }
      if (check && (check.amount == 0 || check.amount - amount_onsell - amount < 0)) {
        notification.error({ message: 'Not enough quantity' });
        history.push('/app/box-profile');
        return;
      }
    } catch (error) {
      history.push('/app/box-profile');
      return;
    }
    try {
      const body = {
        token_id: lootbox.token_id,
        quantity: 1,
        price: price.toString(),
        accepted_payment: currencyAdress,
      };
      const onsellRes = await lootboxService.onsell(body);
      const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
      //add  quyen
      setLoading(true);
      const isApprovedForAll = await commonContract(FACTORY_CONTRACT)
        .methods.isApprovedForAll(address, PAYMENT_CONTRACT)
        .call();
      if (!isApprovedForAll) {
        const res2 = await commonContract(FACTORY_CONTRACT)
          .methods.setApprovalForAll(PAYMENT_CONTRACT, true)
          .send({ from: address, gas: GAS });
      }

      const res = await venderContract(VENDER_CONTRACT)
        .methods.putOnSale(
          currencyAdress,
          onsellRes.data.sale_id + '',
          lootbox.token_id,
          handleDecimal(price, getAddressBykey(currencySystem, typeMoney, 'decimal')),
          amount,
        )
        .send({ from: address, gas: GAS })
        .on('transactionHash', function (hash) {
          setLoading(true);
        })
        .on('error', function (error, receipt) {
          // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
          setLoading(false);
        });

      notification.success({ message: 'Item has been put on sale' });
      history.push('/app/box-profile');
      setModal(false);
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const toggleSuccess = () => setModalSuccess(!modalSucess);
  const selectAfter = (
    <Select
      defaultValue='SPO'
      onChange={(value) => setTypeMoney(value)}
      className='select-after'
      suffixIcon={<CaretDownOutlined />}
    >
      {currencySystem &&
        currencySystem.map((m) => {
          return (
            <Option key={m.id} value={m.label}>
              {m.label}
            </Option>
          );
        })}
    </Select>
  );
  return (
    <Fragment>
      <Button className='btn-mark-lg btn-lootbox' style={{ width: '40%' }} onClick={toggle}>
        Put on sale
      </Button>
      <Modal
        style={{ maxWidth: '600px', width: '100%' }}
        className='put-on-sale-modal'
        backdrop='static'
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader className='header-put-on-sale' toggle={toggle} style={{ fontSize: '32px !important' }}>
          Put on sale
        </ModalHeader>
        <ModalBody className='body-put-on-sale'>
          <div className='note'>Enter the price you would like to sell.</div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img src={lootbox && lootbox.image != '<nil>' ? lootbox.image : ''} alt='' className='image-item' />
          </div>
          <div className='group-btn-sale d-flex'>
            <div className='unit-price lable-input'>
              <span className='lable-cus'>Unit price</span>
              <Input
                type='number'
                addonAfter={selectAfter}
                value={price}
                defaultValue={price}
                onChange={(e) => {
                  handlePrice(e);
                }}
              />
            </div>
            <div className='amount lable-input'>
              <span className='lable-cus'>Amount</span>
              <Input
                defaultValue={amount}
                type='number'
                value={amount}
                onChange={(e) => {
                  handleAmount(e);
                }}
              />
            </div>
          </div>

          <Table className='col-12'>
            <thead>
              <tr>
                <th>Price breakdown</th>
                <th className='column-2'>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope='row'>Selling price</td>
                <td className='column-2'>
                  {price == '' || amount == '' ? 0 : Number((parseFloat(price) * parseInt(amount)).toFixed(6))}{' '}
                  {typeMoney}
                </td>
              </tr>
              <tr>
                <td scope='row'>Commission fee</td>
                <td className='column-2'>
                  {price != '' ? Number(((commissionFee / 100) * parseFloat(price) * amount).toFixed(6)) : 0}{' '}
                  {typeMoney}
                </td>
              </tr>
              <tr>
                <td scope='row'>Secondary royalty</td>
                <td className='column-2'>
                  {lootbox && amount && price != ''
                    ? Number(((lootbox.second_royality_fee / 100) * parseFloat(price) * amount).toFixed(6))
                    : 0}{' '}
                  {typeMoney}
                </td>
              </tr>
            </tbody>
          </Table>

          <div className='receiver d-flex'>
            <div className='txt'>You will receive</div>
            <span className='price-tota'>
              {lootbox &&
              price * amount -
                (commissionFee * amount * price) / 100 -
                (lootbox.second_royality_fee * amount * price) / 100 >=
                0
                ? Number(
                    (
                      price * amount -
                      (commissionFee * amount * price) / 100 -
                      (lootbox.second_royality_fee * amount * price) / 100
                    ).toFixed(6),
                  )
                : 0}{' '}
              {typeMoney}
            </span>
          </div>
        </ModalBody>
        <ModalFooter className='footer-put-on-sale'>
          <Button
            className='btn-mark-lg btn-lootbox btn btn-secondary col-5'
            onClick={handleToggleSuccess}
            style={{ position: 'relative', minWidth: 115 }}
            disabled={loading}
          >
            PUT ON SALE
          </Button>
        </ModalFooter>
        {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
      </Modal>

      <Modal isOpen={modalSucess} toggle={toggleSuccess}>
        <ModalBody>
          <div className='checkout-modal-success d-flex align-item-center'>
            <div className='circle'>
              <img width='60px' height='60px' src={success} alt='' />
            </div>
            <div className='congratulation w-700'>Congratulations!</div>
            <div className='congrat-detail'>
              You have successfuly bought Loot Box{' '}
              <span style={{ color: '#D0B674' }}>{lootbox && lootbox.campaign_title}</span>.
            </div>
            <div className='group-btn-checkout'>
              <Button onClick={() => history.push('/app/box-profile')} className='btn-lootbox btn-mark'>
                Go to Profile
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default PutOnSaleModal;
