import { Link, makeStyles } from '@material-ui/core';
import { ColorButton } from 'components';
import { color } from 'utils/constants';

const Feedback = () => {
  const classes = useStyles();
  return (
    <div className='App-Feedback'>
      <Link href='https://spores.nolt.io/' target='_blank'>
        <ColorButton
          hexColor={color.semiBlack}
          className={classes.action}
          startIcon={<img src='/assets/icons/logo-circle.png' style={{ width: 22 }} />}
        >
          Feedback
        </ColorButton>
      </Link>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  action: {
    position: 'absolute',
    transform: 'rotate(-90deg) translate(50%, 100%)',
    right: -5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default Feedback;
