import { notification, message } from 'antd';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
export { default as jwtDecode } from 'jwt-decode';
export { default as moment } from 'moment';

export const onFormError = () => {
  message.error('Please check again form values');
};

export const onCatchError = (error) => {
  console.log(error);
  if (error.code !== 4001) {
    notification.error({ message: 'Unexpected errors occurred' });
  }
};

export const formatPrice = (price) => Number(price).toLocaleString();

export const t = (message) => <Translation>{(t) => t(message)}</Translation>;

export const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

export const shorten = (hash) => {
  if (typeof hash !== 'string') return hash;
  if (hash.length !== 42) return hash;
  return hash.slice(0, 12) + '...' + hash.slice(36);
};

export const shortenAddress = (hash, start, end) => {
  if (!hash) {
    return;
  }
  return hash.slice(0, start) + '...' + hash.slice(hash.length - end, hash.length);
};

export const shorterName = (name, size) => {
  if (!size) {
    size = 15;
  }
  if (name == '' || !name) {
    return 'No name';
  }
  if (name.length > size) {
    return name.slice(0, size - 1) + '...';
  }
  return name;
};
export const getAddressBykey = (objects, key, field) => {
  if (!objects) {
    return null;
  }
  const filter = objects.filter((f) => f.label == key);
  if (filter && filter.length > 0) {
    return filter[0][field];
  }
  return null;
};
export const handlePriceItem = (price, decimal) => {
  if (!price || price == '0') {
    return 0;
  }
  let tem = [...price];
  tem.splice(tem.length - decimal, 0, '.');
  let del = 0;
  for (let i = tem.length - 1; i >= 0; i--) {
    if (tem[i] == '0') {
      del++;
    } else if (tem[i] != '0') {
      break;
    }
  }
  const temp2 = tem.join('');
  return parseFloat(temp2.slice(0, temp2.length - del));
};
export const getAddressById = (objects, key, field) => {
  if (!objects) {
    return null;
  }
  const filter = objects.filter((f) => f.id == key);
  if (filter && filter.length > 0) {
    return filter[0][field];
  }
  return null;
};
