import React, { Component, Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import './boxDetail.scss';

import Artist from 'components/box/Artist';
import DropList from 'components/box/DropList';
import AboutArtist from 'components/box/AboutArtist';
import BoxInfor from 'components/box/BoxInfor';
import { lootboxService } from 'services/lootbox/lootbox';
import { campaignService } from 'services/lootbox/campaign';
import c from 'assets/images/c.png';
import s from 'assets/images/s.png';
import r from 'assets/images/r.png';
import e from 'assets/images/e.png';
import rarity from 'assets/images/l.png';
import SeriaContent from 'components/box/SerialContent';
import { Button, Space, Card } from 'antd';
const DetailBox = () => {
  // const [lootbox, setLootbox] = useState(null);
  // const [campaign, setCampaign] = useState(null)
  const { id } = useParams();
  const [serialContent, setSerialContent] = useState(null);
  const [dropItem, setDropItem] = useState([]);
  const [arrayTotal, setArrayTotal] = useState({});
  const [lootbox, setLootbox] = useState(null);
  const [view, setView] = useState('seria');
  const history = useHistory();
  const [commissionFee, setCommission] = useState(0);
  useEffect(async () => {
    if (!lootbox) {
      return;
    }
    let summedAges = lootbox.LootboxCampaign.nfts.reduce((a, c) => {
      let filtered = a.filter((el) => el.nft_type_id === c.nft_type_id);
      if (filtered.length > 0) {
        a[a.indexOf(filtered[0])].total_supply += +c.total_supply;
        a[a.indexOf(filtered[0])].amount += +1;
      } else {
        const tem = { ...c, amount: 1 };
        a.push(tem);
      }
      return a;
    }, []);
    // eslint-disable-next-line no-extend-native
    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop];
      }
      return total;
    };
    let arraySum = {
      totalAmount: summedAges.sum('amount'),
      totalSupply: summedAges.sum('total_supply'),
    };
    setArrayTotal(arraySum);
    setDropItem([...summedAges]);
  }, [lootbox]);

  useEffect(async () => {
    try {
      const res = await lootboxService.secondSelaItem(id);
      setLootbox(res.data);
    } catch (error) {
      history.push('/app/market');
    }
  }, []);

  const handleImage = (name) => {
    if (name == 'Legendary') {
      return rarity;
    }
    if (name == 'Epic') {
      return e;
    }
    if (name == 'Super Rare') {
      return s;
    }
    if (name == 'Rare 1') {
      return r;
    }
    if (name == 'Rare 2') {
      return r;
    }
    if (name == 'common') {
      return c;
    }
  };
  return (
    <Fragment>
      <div className='product-detail-page'>
        <div className='container'>
          <BoxInfor lootbox={lootbox} />
          <div className='row series-content'>
            <div className='col-12'>
              <div className='row title-series-content p-0'>
                <Space style={{ marginBottom: 16 }}>
                  <Button
                    className={`btn-handle ${view == 'seria' ? 'btn-acctive' : ''}`}
                    onClick={() => setView('seria')}
                  >
                    Series Content
                  </Button>
                  <Button
                    className={`btn-handle ${view == 'dropItem' ? 'btn-acctive' : ''}`}
                    onClick={() => setView('dropItem')}
                  >
                    Drop Items
                  </Button>
                  <Button
                    className={`btn-handle ${view == 'artirt' ? 'btn-acctive' : ''}`}
                    onClick={() => setView('artirt')}
                  >
                    About Artist
                  </Button>
                </Space>
              </div>
              {view && view === 'seria' && <SeriaContent dropItem={dropItem} arrayTotal={arrayTotal} />}
              {view && view === 'dropItem' && <DropList lootbox={lootbox ? lootbox.LootboxCampaign.nfts : []} />}
              {view && view === 'artirt' && <AboutArtist lootbox={lootbox ? lootbox.LootboxCampaign : null} />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DetailBox;
