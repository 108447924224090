import React from 'react';
import robot from '../../assets/images/robot.png';

import './boxDetail.scss';
import { Card, CardBody, CardImg, CardText, Button } from 'reactstrap';
import { Select, List } from 'antd';
import { images } from 'assets/images';
import c from 'assets/images/c.png';
import s from 'assets/images/s.png';
import r from 'assets/images/r.png';
import e from 'assets/images/e.png';
import rarity from 'assets/images/l.png';
import { API_URI_LOOTBOX } from 'env';

export default function DropItem(props) {
  const { drop } = props;
  const genClass = (name) => {
    if (name == 1) {
      return 'rarity_btn rarity_legend';
    }
    if (name == 2) {
      return 'rarity_btn rarity_epic';
    }
    if (name == 3) {
      return 'rarity_btn rarity_super_rare';
    }
    if (name == 4) {
      return 'rarity_btn rarity_rare';
    }
    return 'rarity_btn rarity_common';
  };

  const genClassName = (id) => {
    if (id == 1) {
      return 'Legendary';
    }
    if (id == 2) {
      return 'Epic';
    }
    if (id == 3) {
      return 'Super rare';
    }
    if (id == 4) {
      return 'Rare';
    }
    if (id == 5) {
      return 'Common';
    }
    return '';
  };
  return (
    <div style={{ border: '1px solid #ddd5d5' }} className='card-item check17'>
      {/*<Button className={genClass(drop.nft_type_id)} style={{width : 110}}>{drop && genClassName(drop.nft_type_id)}</Button>*/}
      <h5 className={`drop-item-rarity ` + genClass(drop.nft_type_id)}>{drop && genClassName(drop.nft_type_id)}</h5>
      <CardImg top src={drop && drop.image != '<nil>' ? drop.image : robot} alt='Card image cap' />
      {/* <CardBody>
          <CardText>
          <div className="d-flex justify-content-between mt-4 align-items-center">
          <h5>{drop && drop.name}</h5>
            <img src={drop.rarity_name == "Legandary" ? rarity : drop.rarity_name == "Epic" ? e : drop.rarity_name == "Supper rate" ? s : drop.rarity_name == "Rate" ? r : c} width="29px" alt="" />
            </div>
          </CardText>
          <CardText>
            <div className="d-flex justify-content-between mt-4 align-items-center">
              <div className="text-coin">{drop && drop.total_supply} left</div>
            </div>
          </CardText>
        </CardBody> */}
      <CardBody className='drop-item'>
        <CardText className='d-flex justify-content-between'>
          <h4 className='drop-name-item'>{drop && drop.name}</h4>
          <h6 className='drop-amount-item'>
            {drop && drop.total_supply} item{drop && drop.total_supply > 1 ? 's' : ''}
          </h6>
        </CardText>
      </CardBody>
    </div>
  );
}
