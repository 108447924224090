import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import coin from 'assets/images/coin.png';
import { Table } from 'reactstrap';
import '../checkout/checkout.scss';
import { useHistory } from 'react-router';
import { checkoutService } from 'services/lootbox/checkout';
import {
  API_URI_LOOTBOX,
  SPO_CONTRACT,
  VENDER_CONTRACT,
  PAYMENT_CONTRACT,
  USDC_CONTRACT,
  USDT_CONTRACT,
  BUSD_CONTRACT,
  GAS,
} from 'env';
import { paymentContract, spoContract, usdtContract, usdcContract, busdContract } from 'contracts/lootbox/index';
import { Box } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import Web3 from 'web3';
import success from 'assets/images/success-ic.png';
import { lootboxService } from 'services/lootbox/lootbox';
import { blue } from '@material-ui/core/colors';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { getAddressById, handlePriceItem } from 'utils/common';

const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
const BuyItem = (props) => {
  const classes = useStyles();
  const { className, item } = props;

  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [capchar, setCapchar] = useState(false);
  const [order, setOrder] = useState(null);
  const [modalSucess, setModalSuccess] = useState(false);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const { currencySystem } = useSelector(({ common }) => common);
  const toggle = async () => {
    setCapchar(false);
    setModal(!modal);
    // const { data: res } = checkoutService.order(id);
    // setOrder(res);
  };
  const [loading, setLoading] = useState(false);

  const handleToggleSuccess = async () => {
    if (!capchar) {
      return;
    }
    const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    let contract = null;
    let currency = getAddressById(currencySystem, item.Currency.id, 'adress');
    switch (item.Currency.id) {
      case 1:
        contract = spoContract;
        break;
      case 2:
        contract = usdtContract;
        break;
      case 3:
        contract = usdcContract;
        break;
      case 4:
        contract = busdContract;
        break;
    }
    if (!contract || !currency) {
      notification.error({ message: 'Currency incorrect' });
      return;
    }

    //check money
    const decimal = getAddressById(currencySystem, item.Currency.id, 'decimal');

    const balance = await contract(currency).methods.balanceOf(address).call();
    const priceBuy =
      handlePriceItem(item.Onsell.price, getAddressById(currencySystem, item.Currency.id, 'decimal')) *
      item.Onsell.quantity;

    if (priceBuy > handlePriceItem(balance, decimal)) {
      notification.error({ message: 'Not enought money' });
      setLoading(false);
      return null;
    }
    setLoading(true);
    try {
      await lootboxService.detailresellItem(item.Onsell.id);
    } catch (error) {
      notification.error({ message: 'Item not found' });
      setLoading(false);
      history.push('/app/market');
      return;
    }

    const p = handlePrice(priceBuy, decimal);
    const allow = await contract(currency)
      .methods.approve(PAYMENT_CONTRACT, p)
      .send({ from: address, gasLimit: GAS })
      .on('transactionHash', function (hash) {
        setLoading(true);
      })
      .on('error', function (error, receipt) {
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        setLoading(false);
      });
    const res = await paymentContract(PAYMENT_CONTRACT)
      .methods.buyItem(item.Onsell.sale_id + '', item.Onsell.quantity)
      .send({ from: address, gasLimit: GAS })
      .on('transactionHash', function (hash) {
        setLoading(true);
      })
      .on('error', function (error, receipt) {
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        setLoading(false);
      });
    setModal(false);
    setModalSuccess(true);
    setLoading(false);
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handlePrice = (price, number) => {
    let temp = parseFloat(price);
    for (let i = 0; i < number; i++) {
      temp = temp * 10;
      if (isInt(temp)) {
        let v = number - (i + 1);
        for (let j = 0; j < v; j++) {
          temp = temp + '0';
        }
        return temp;
      }
    }
  };

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  const handleSetChap = (e) => {
    if (e) {
      setCapchar(true);
    } else {
      setCapchar(false);
    }
  };

  const toggleSuccess = () => setModalSuccess(!modalSucess);
  return (
    <div className='col-12'>
      {/*<Button className="buy btn-mark-lg" style={{ margin: "0px" }} onClick={toggle}>BUY NOW</Button>*/}
      <a onClick={toggle} className='a-button a-button-color a-bg-color-yellow'>
        BUY NOW
      </a>
      <Modal className='checkout-modal' isOpen={modal} toggle={toggle} backdrop='static'>
        <ModalHeader className='header-checkout' toggle={toggle}>
          Checkout
        </ModalHeader>
        <ModalBody className='body-checkout'>
          <Table className='col-12'>
            <thead>
              <tr>
                <th>Item</th>
                <th style={{ textAlign: 'right' }}>Price</th>
              </tr>
            </thead>
            <tbody>
              {[{}].map((data, key) => {
                return (
                  <tr key={key}>
                    <td scope='row'>
                      <img src={item ? item.Onsell.NftProfile.image : ''} width='40px' />{' '}
                      <span style={{ fontWeight: 700, color: '#CFB675', fontSize: '16px' }}>
                        {item ? item.Onsell.NftProfile.name : ''} (x{item && item.Onsell.quantity})
                      </span>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {item &&
                        handlePriceItem(
                          item.Onsell.price + '',
                          getAddressById(currencySystem, item.Currency.id, 'decimal'),
                        ) * item.Onsell.quantity}{' '}
                      {item && item.Currency.label}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td scope='row' style={{ fontWeight: 600 }}>
                  Total
                </td>
                <td style={{ textAlign: 'right', fontWeight: 700, color: '#CFB675' }}>
                  {item &&
                    handlePriceItem(
                      item.Onsell.price + '',
                      getAddressById(currencySystem, item.Currency.id, 'decimal'),
                    ) * item.Onsell.quantity}{' '}
                  {item && item.Currency.label}
                </td>
              </tr>
              <tr>
                <td scope='row' colSpan='2'>
                  <Box className='d-flex justify-content-center' style={{ textAlign: 'center', width: '100%' }}>
                    <ReCAPTCHA
                      onChange={(e) => {
                        handleSetChap(e);
                      }}
                      className='recap'
                      sitekey='6Lf0i4EcAAAAAOYMw3gt-khTM16WHHwDTwbtINP2'
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className='footer-checkout justify-content-center'>
          <Button
            className='btn-checkout btn-mark-lg'
            style={{ textTrasform: 'uppercase', border: 'none', fontWeight: 600, width: '300px' }}
            disabled={loading}
            onClick={handleToggleSuccess}
          >
            CHECKOUT
          </Button>
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalSucess} toggle={toggleSuccess} className={className}>
        <ModalBody>
          <div className='checkout-modal-success d-flex align-item-center'>
            <div className='circle'>
              <img width='60px' height='60px' src={success} alt='' />
            </div>
            <div className='congratulation w-700'>Congratulations!</div>
            <div className='congrat-detail'>
              You have successfuly bought Loot Box{' '}
              <span style={{ color: '#D0B674' }}>{item.Onsell.NftProfile.name}</span>.
            </div>
            <div className='group-btn-checkout'>
              <Button onClick={() => history.push('/app/box-profile')} className='btn-lb btn-mark-wh'>
                Go to Profile
              </Button>
              <Button onClick={() => history.push('/app/home')} className='btn-checkout btn-mark-lg'>
                Continue buying
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BuyItem;
