import React, { Fragment, useState } from 'react';
import avatar from 'assets/images/avatar.png';
import coin from 'assets/images/coin.png';
import height from 'assets/images/heigh.png';
import { Card, Button } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PutOnSaleModal from '../put-on-sale/PutOnSaleModal';

import './item.scss';
import lootbox from 'reducers/loobox/lootbox';
import shape from '../../assets/images/shape.png';
import { API_URI_LOOTBOX } from 'env';
import { useSelector } from 'react-redux';
import { shorterName } from 'utils/common';
import { Modal } from 'antd';

export default function ItemInfor(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { lootbox, commissionFee } = props;
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const handleValue = (userJwt) => {
    return userJwt.substr(0, 6) + '...' + userJwt.substr(userJwt.length - 6, userJwt.length - 1);
  };
  const genClass = (name) => {
    if (name == 'Legendary') {
      return 'rarity_btn rarity_legend';
    }
    if (name == 'Epic') {
      return 'rarity_btn rarity_epic';
    }
    if (name == 'Super Rare') {
      return 'rarity_btn rarity_super_rare';
    }
    if (name == 'Rare') {
      return 'rarity_btn rarity_rare';
    }
    return 'rarity_btn rarity_common';
  };
  return (
    <Fragment className='flex-row'>
      <div className='col-md-5 col-xs-12 pl-0 image-item'>
        <div className='flex-center col-12 p-24' style={{ maxHeight: 600 }}>
          <img
            onClick={showModal}
            src={lootbox?.image}
            className='cursor-pointer'
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </div>

        <Modal
          className='popup-image'
          closable='true'
          visible={isModalVisible}
          width='50%'
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <img style={{ width: '100%' }} src={lootbox && lootbox.image != '<nil>' ? lootbox.image : coin} />
        </Modal>
      </div>
      <div className='col-md-7 col-xs-12 detail-item-infor d-flex flex-column'>
        {/*<Card className="card-itembox row flex-row col-12">*/}
        <div>
          <div className='col-12 p-0 title'>
            <div className='club'>{lootbox && lootbox.campaign_title}</div>
            <span className='subject'>{lootbox && lootbox.name}</span>
            <div className='d-flex'>
              {/*<a style={{ width: 87, marginRight:5 }} className={`a-button a-bg-color-yellow ` + genClass(lootbox && lootbox.nft_types)}>{lootbox && lootbox.nft_types} </a>*/}
              <h5 className={`drop-item-rarity mr-2 ` + genClass(lootbox && lootbox.nft_types)}>
                {lootbox && lootbox.nft_types}
              </h5>
              {lootbox && (
                <span>
                  {' '}
                  {(lootbox.amount_onsell ? lootbox.amount - lootbox.amount_onsell : lootbox.amount) > 1
                    ? `${lootbox.amount_onsell ? lootbox.amount - lootbox.amount_onsell : lootbox.amount} editions`
                    : `${lootbox.amount_onsell ? lootbox.amount - lootbox.amount_onsell : lootbox.amount} edition`}
                </span>
              )}
            </div>
          </div>
          <div className='col-12 p-0 item-content'>
            <div className='description-title'>Description</div>
            <div className='author-infor'>
              <div className='author'>
                <img
                  src={lootboxProfile && lootboxProfile.avatar ? lootboxProfile.avatar : avatar}
                  alt=''
                  width='24'
                  className='avattar'
                />
                <span>Owner by</span>{' '}
                <span style={{ fontWeight: 700, marginLeft: 5 }}>
                  {' '}
                  {lootboxProfile && shorterName(lootboxProfile.fullname)}
                </span>
              </div>
              <div className='address'>
                <span>{lootboxProfile && handleValue(lootboxProfile.wallet_address)}</span>
                <CopyToClipboard text={lootboxProfile && lootboxProfile.wallet_address}>
                  <img src={shape} alt='' />
                </CopyToClipboard>
              </div>
            </div>
            <span className='content'>{lootbox && lootbox.description}</span>
          </div>
        </div>
        <div className='align-items-end check5' style={{ marginTop: '20px', width: '100%', direction: 'rtl' }}>
          {lootboxProfile && lootbox && lootboxProfile.id == lootbox.user_id && (
            <PutOnSaleModal lootbox={lootbox} commissionFee={commissionFee} />
          )}
        </div>
      </div>
    </Fragment>
  );
}
