import React, { Fragment, useEffect, useState } from 'react';

import './boxDetail.scss';
import { Button } from 'reactstrap';

import avatar from '../../assets/images/avatar.png';
import { API_URI_LOOTBOX } from 'env';
import coin from '../../assets/images/coin.png';
import { authorService } from 'services/lootbox/authorService';

export default function BoxInfor(props) {
  const { lootbox } = props;
  return (
    <Fragment>
      {/*        <div className="about-artist">
            <div className="row">
            <div className="col-md-5 col-xs-12">
                <img src={lootbox?API_URI_LOOTBOX + lootbox.artist_avatar: avatar} width="100%" />{" "}           
            </div>
            <div className="about-actist-dt col-md-7 col-xs-12">
                <div className="row about-artist-tt">
                    About Artist
                </div>
                <div className="row artist-subject">
                   <div className="name-box ">
                       <div className="name">{lootbox && lootbox.artist_name}</div>
                   </div>
                </div>
                <div className="row artist-content">
                {lootbox && lootbox.artist_description}
                </div>
                <div className="row view-profile">

                </div>
            </div>

            </div>
        </div>*/}
      <div className='row col-12 about-artist d-flex p-0'>
        <div className='img-artist p-0 pr-5 col-6'>
          <img src={lootbox ? lootbox.artist_avatar : avatar} className='img-art' />
        </div>
        <div className='text-artist p-0 col-6'>
          <h3>About artist</h3>
          <div className='intro-art'>
            <h2>{lootbox && lootbox.artist_name}</h2>
            <div style={{ color: '#7A7F9A' }}>{lootbox && lootbox.artist_description}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
