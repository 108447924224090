import React, { Fragment, useState } from 'react';

import './open.scss';
import { CardImg, Button } from 'reactstrap';
import { images } from 'assets/images';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { API_URI_LOOTBOX } from 'env';
const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
export default function Item(props) {
  const classes = useStyles();
  const { openDetail, loading } = props;
  const genClass = () => {
    if (openDetail) {
      if (openDetail.nft_type.id == 1) {
        return 'rarity_btn rarity_legend';
      }
      if (openDetail.nft_type.id == 2) {
        return 'rarity_btn rarity_epic';
      }
      if (openDetail.nft_type.id == 3) {
        return 'rarity_btn rarity_super_rare';
      }
      if (openDetail.nft_type.id == 4) {
        return 'rarity_btn rarity_rare';
      }
      if (openDetail.nft_type.id == 5) {
        return 'rarity_btn rarity_common';
      }
      return '';
    }
  };
  return (
    <div className='item'>
      <Fragment>
        {loading ? (
          <div className='loading-image'>
            {/* <CircularProgress className={classes.buttonProgress} size={24} /> */}
            <img
              top
              width='100%'
              style={{ minHeight: '240px', marginBottom: 0 }}
              src='https://sa.tinhte.vn/2014/03/2435933_vutru.gif'
              alt='Card image cap'
              className='image-card'
            />
          </div>
        ) : (
          <Fragment>
            <div>
              <CardImg
                top
                width='100%'
                src={openDetail && openDetail.user_nft.image != '<nil>' ? openDetail.user_nft.image : ''}
                alt='Card image cap'
                className='image-card'
              />
            </div>
          </Fragment>
        )}

        <div style={{ width: '100%', textAlign: 'center' }}>
          {openDetail && <Button className={genClass()}>{openDetail && openDetail.nft_type.label}</Button>}
        </div>
      </Fragment>
    </div>
  );
}
