import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppMenu } from 'containers';
import { NetworkBar, SearchBar, ColorButton } from 'components';
import { Paper, Hidden, IconButton, Drawer } from '@material-ui/core';
import { List, ListItem } from '@material-ui/core';
import { Dropdown } from 'antd';
import { profileAction } from 'actions/profile';
import { privateRoute } from 'routes';
import { UserAvatar } from 'views/lootbox/ProfileUpdate/components';
import { lbprofileAction } from 'actions/lootbox/profile';

import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

const Header = () => {
  const { isLoggedIn, mintable = false } = useSelector(({ profileLootbox }) => profileLootbox);

  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const handleOpenMenu = (open) => (event) => setIsOpenMenu(open);

  const handleClickConnect = () => {
    lbprofileAction.connect();
  };

  const handleClickDisconnect = () => {
    lbprofileAction.signOut();
  };

  return (
    <Paper square className='App-Header' color='primary' style={{ padding: '30px calc(50% - 720px)' }}>
      <div className='mr-24' />
      <Hidden xsDown>
        <Link to={privateRoute.home.path} className='mr-20'>
          <img src='/assets/icons/logo-primary.svg' height={40} />
        </Link>
      </Hidden>
      <NetworkBar />

      <div style={{ flex: 2 }} />
      {/* <Hidden smDown>
        <SearchBar />
      </Hidden> */}
      <Hidden smDown>
        <AppMenu />
      </Hidden>
      <Hidden mdUp>
        <IconButton onClick={handleOpenMenu(true)}>
          <MenuOutlinedIcon />
        </IconButton>

        <Drawer anchor='top' open={isOpenMenu} onClose={handleOpenMenu(false)}>
          <List style={{ width: '100%' }}>
            <AppMenu onClickMenu={handleOpenMenu(false)} />
          </List>
        </Drawer>
      </Hidden>

      {isLoggedIn ? (
        <>
          <Dropdown
            trigger='click'
            placement='bottomRight'
            getPopupContainer={(event) => event.parentNode}
            overlay={
              <div>
                <List disablePadding component={Paper} style={{ marginTop: 6, minWidth: 180 }}>
                  <ListItem button divider component={Link} to={privateRoute.boxProfile.path}>
                    Profile
                  </ListItem>
                  <ListItem button onClick={handleClickDisconnect}>
                    Disconnect
                  </ListItem>
                </List>
              </div>
            }
          >
            <div style={{ padding: 8, marginRight: 12, cursor: 'pointer' }}>
              <UserAvatar size={32} border={2} />
            </div>
          </Dropdown>
          {mintable && (
            <Link to={privateRoute.createType.path}>
              <ColorButton startIcon={<CloudUploadOutlinedIcon />}>Create</ColorButton>
            </Link>
          )}
        </>
      ) : (
        <ColorButton onClick={handleClickConnect}>Connect wallet</ColorButton>
      )}
      <div className='mr-24' />
    </Paper>
  );
};

export default Header;
