import React, { Fragment } from 'react';
import avatar from 'assets/images/avatar.png';
import { Timeline } from 'antd';
import { fake_timeline } from 'assets/images';

import './item.scss';
import { API_URI_LOOTBOX } from 'env';
import { getAddressById, handlePriceItem, shorterName } from 'utils/common';
import { useSelector } from 'react-redux';

export default function HistoryLineItemSale(props) {
  const { items, currency } = props;
  const { currencySystem } = useSelector(({ common }) => common);
  return (
    <Fragment>
      <Timeline className='itemSaleHistory'>
        {items &&
          items.map((m, key) => {
            return (
              <Timeline.Item className='event' key={key}>
                <div className='history-item d-flex'>
                  <img src={m.avatar && m.avatar != '' ? m.avatar : avatar} width='30px' height='30px' alt='' />
                  <div className='name-time d-flex flex-column col-9'>
                    <span className='action'>{m.action}</span>
                    <span className='time'>
                      By {shorterName(m.fullname)} at {m.time_stamp}
                    </span>
                  </div>
                  <div className='price' style={{ width: 'calc(20% - 30px)' }}>
                    {m.price && (
                      <span className='price-usdc'>
                        {handlePriceItem(m.price + '', getAddressById(currencySystem, m.currency_id, 'decimal'))}{' '}
                        {m.currency_label}
                      </span>
                    )}
                  </div>
                </div>
              </Timeline.Item>
            );
          })}
      </Timeline>
    </Fragment>
  );
}
