import React, { Fragment, useEffect, useState } from 'react';
import DropItem from './DropItem';
import { Space, Select, Row, Col, List } from 'antd';
import { campaignService } from 'services/lootbox/campaign';
import './boxDetail.scss';
import { Card, Button } from 'reactstrap';

export default function DropList(props) {
  const { lootbox } = props;
  return (
    <Fragment>
      <div className='col-12 p-0'>
        <div className='row list-drop-items'>
          <div className='list-item'>
            {/* {[ {}, {}, {}, {},{}, {}, {}, {}, {}, {}].map((item) => {
                                    return <DropItem/>;
                                    })} */}
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 4,
                xxl: 5,
              }}
              dataSource={lootbox}
              renderItem={(item) => (
                <List.Item>
                  <DropItem drop={item} />
                </List.Item>
              )}
            />
          </div>
        </div>
        {/* <div className="d-flex justify-content-center">
                                <Button className="btn-showmore">
                                    Show more
                                </Button>
                            </div> */}
      </div>
    </Fragment>
  );
}
