import robot from 'assets/images/robot.png';
import avatar from 'assets/images/avatar.png';
import { Card, CardBody, CardImg, CardText } from 'reactstrap';
import { Button } from 'antd';
import './boxProfile.scss';
import lootbox from 'assets/images/lootbox.png';
import opened from 'assets/images/opened.png';
import { Fragment } from 'react';
import { API_URI_LOOTBOX } from 'env';
import { getAddressById, getAddressBykey, handlePriceItem, shorterName } from 'utils/common';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#cfb675e8',
    color: 'white',
    maxWidth: '300px',
    padding: '10px',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function ItemMarket(props) {
  const { item, type } = props;
  const { currencySystem } = useSelector(({ common }) => common);

  const price =
    item && item.type === 'box'
      ? item.resell_price
      : handlePriceItem(item.price + '', getAddressBykey(currencySystem, item.currency_label, 'decimal')) *
        item.quantity;
  const currencyId = item && item.type === 'box' ? item.currency_id : 2;
  const code = item && item.type === 'box' ? item.code_number : item.id;
  const rarity_array = ['Legendary', 'Epic', 'Supper rate', 'Rate', 'Common', 'Box'];
  // const rarity_name = rarity_array[item && item.type === "item" ? (Math.floor(Math.random() * 4)) : 0];

  const diffDate = (endtime) => {
    const date1 = new Date();
    const date2 = new Date(endtime);
    const diffTime = Math.abs(date2 - date1);

    if (diffTime > 0) {
      const day = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[0];
      const hour = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[1];
      const minute = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[2];
      if (day && day != '00') {
        return day + ' days left to open';
      }
      if (hour && hour != '00') {
        return hour + ' hour left to open';
      }
      if (minute && minute != '00' && minute != '01') {
        return minute + ' minutes left to open';
      }
    }
    return '';
  };

  const genClass = (name) => {
    if (name == 'Legendary') {
      return 'rarity_btn rarity_legend';
    }
    if (name == 'Epic') {
      return 'rarity_btn rarity_epic';
    }
    if (name == 'Supper Rare') {
      return 'rarity_btn rarity_super_rare';
    }
    if (name == 'Rare') {
      return 'rarity_btn rarity_rare';
    }
    if (name == 'Common') {
      return 'rarity_btn rarity_common';
    }
    return 'rarity_btn lootbox';
  };
  let longName =
    `${item && item.type == 'box' ? item.campaign_title : item.name}` +
    ' ' +
    `${item && item.type == 'box' ? '#' + item.id : ''}`;

  return (
    <div className='item col-xs-12'>
      <Card className='card-item'>
        <div className='col-12 p-0 pb-2 pt-2' style={{ minHeight: 48 }}>
          {item.type == 'box' ? (
            <span>{diffDate(item.open_end_time)}</span>
          ) : (
            <Fragment>
              <h5 className={genClass(item.nft_types)}>{item && item.nft_types}</h5>
              <Button className='number_edition_btn'>x{item && item.quantity}</Button>
            </Fragment>
          )}
        </div>
        <div className='square-image'>
          <div
            className='image'
            style={{
              backgroundImage: `url( ${item && item.type == 'box' ? item.campaign_box_image : item.image})`,
              height: '100%',
              width: '100%',
            }}
          ></div>
        </div>
        {/*<CardImg top src={item && item.type=="box"?item.campaign_box_image:item.image} alt='Card image cap' />*/}
        <CardBody className='pt-12 pb-0'>
          <CardText>
            <HtmlTooltip title={longName.length > 16 ? longName : ''}>
              <span className='name'>
                {item && item.type == 'box' ? shorterName(item.campaign_title, 16) : shorterName(item.name, 16)} &nbsp;{' '}
                {item && item.type == 'box' ? '#' + item.id : ''}
              </span>
            </HtmlTooltip>
            <br />
            <span className='owner'>
              <img
                src={
                  item && item.type == 'item'
                    ? item.user_avatar
                      ? item.user_avatar
                      : avatar
                    : item.user_avatar
                    ? item.user_avatar
                    : avatar
                }
              />
              {item && item.user_fullname ? shorterName(item.user_fullname) : 'No name'}
            </span>
          </CardText>
          <CardText className='pice-div pt-0'>
            <span className='price-title'>Price</span>
            <span className='price'>
              {price}{' '}
              {item && item.type == 'item'
                ? item.currency_label
                : item.type == 'box'
                ? getAddressById(currencySystem, item.currency_id, 'label')
                : ''}{' '}
            </span>
          </CardText>
        </CardBody>
      </Card>
    </div>
  );
}
